import './App.css';
import Hangman from './components/hangman/Hangman';


function App() {

  return (
    <>
      <Hangman />
    </>
  );
}

export default App;
